<template>
  <div>
    <Form ref="form" :model="form" label-width="80px">
      <FormItem label="视频一：">
        <UploadImgAndVideo></UploadImgAndVideo>
        <div class="video-content">
          <div class="video-player" v-if="!!video1">
            <i class="el-icon-circle-close" @click="delVideo(1)"></i>
            <video controls class="qs-video" :src="video1"></video>
          </div>
          <span class="video-content-desc" v-else>暂无视频，请上传！！！</span>
        </div>
      </FormItem>
      <FormItem label="视频二：">
        <UploadImgAndVideo></UploadImgAndVideo>
        <div class="video-content">
          <div class="video-player" v-if="!!video2">
            <i class="el-icon-circle-close" @click="delVideo(2)"></i>
            <video controls class="qs-video" :src="video2"></video>
          </div>
          <span class="video-content-desc" v-else>暂无视频，请上传！！！</span>
        </div>
      </FormItem>
    </Form>
  </div>
</template>
<script>
import { Form, FormItem } from "element-ui";
import UploadImgAndVideo from "../components/UploadImgAndVideo.vue";
export default {
  components: {
    Form,
    FormItem,
    UploadImgAndVideo,
  },
  data() {
    return {
      video1:
        "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/videos/shouye123.mp4",
      video2:
        "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/videos/pinpaizeren.mp4",
    };
  },
  methods: {
    delVideo(count) {
      if (count == 1) {
        this.video1 = "";
      }
      if (count == 2) {
        this.video2 = "";
      }
    },
  },
};
</script>
<style lang="scss">
.video-content {
  position: relative;
  width: 300px;
  height: 200px;
  border: 1px solid #efefef;
  margin-top: 10px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  .video-content-desc {
    color: #efefef;
  }
  .qs-video {
    width: 100%;
    max-height: 200px;
  }
  .el-icon-circle-close {
    position: absolute;
    right: -10px;
    top: -10px;
    font-size: 20px;
    color: red;
    z-index: 100;
    cursor: pointer;
  }
}
</style>
